import { Component } from '@angular/core';
import { TokenStorage } from './token.storage';
import { AuthenticationService } from './core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private authService: AuthenticationService,
    private tokenStorage: TokenStorage
  ) {}

  ngOnInit() {
    this.saveLoggedInUserDetails();
  }

  saveLoggedInUserDetails() {
    const currentuser = this.authService.currentUser();
    if (currentuser && currentuser.access_token) {
      if (!this.tokenStorage.getUserDetails()) {
        this.authService.getUser();
      }
    }
  }
}

export const APP_URL = environment.api_url;
export const SuperAdmin1 = 'super_admin';

export const Type_A_Com_Admin = 'ct_a_authority_admin';
export const Type_A_Com_User = 'ct_a_authority_user';

export const Type_B_Com_Admin = 'ct_b_adviser_admin';
export const Type_B_Com_User = 'ct_b_adviser_user';

export const Type_C_Com_Admin = 'ct_c_partner_admin';
export const Type_C_Com_User = 'ct_c_partner_user';

export const AUTHORITY_TOP_ID = '1';
export const AUTHORITY_ID = '2';
export const CONSULTANT_ID = '3';
export const DEVELOPMENT_PARTNER_ID = '4';
