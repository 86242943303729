const notificationItems = [
    // {
    //     icon: 'uil uil-user-plus',
    //     color: 'primary',
    //     title: 'New user registered.',
    //     text: '5 hours ago'
    // },
    // {
    //     image: 'assets/images/users/avatar-1.jpg',
    //     title: 'Karen Robinson',
    //     text: 'Wow ! this admin looks good and awesome design'
    // },
    // {
    //     image: 'assets/images/users/avatar-2.jpg',
    //     title: 'Cristina Pride',
    //     text: 'Hi, How are you? What about our next meeting'
    // },
    // {
    //     icon: 'uil uil-comment-message',
    //     color: 'success',
    //     title: 'Jaclyn Brunswick commented on Dashboard',
    //     text: '1 min ago'
    // },
    // {
    //     icon: 'uil uil-comment-message',
    //     color: 'danger',
    //     title: 'Caleb Flakelar commented on Admin',
    //     text: '4 days ago'
    // },
    // {
    //     icon: 'uil uil-heart',
    //     color: 'primary',
    //     title: 'Carlos Crouch liked',
    //     text: '13 days ago'
    // }
];

export { notificationItems };
