import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize, map } from 'rxjs/operators';
import { LoginUserRO } from '../models/auth.models';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private http: HttpClient) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const authHeader = request.headers.get('Authorization');
        const isLoggedIn = authHeader && authHeader.startsWith('Bearer fake-jwt-token');


        // wrap in delayed observable to simulate server api call
        return of(null).pipe(mergeMap(() => {

            // authenticate - public
            if (request.url.endsWith('/api/auth/login') && request.method === 'POST') {
                const {email,password}=request.body;
                const API_URL=`${environment.api_url}/api/auth/roles`;
                this.http.post<LoginUserRO>(`/api/auth/login`, {email, password })
                        .pipe(map(user => {
                            if(!user.success){
                                return error(user.data);
                            }

                            return ok(user);
                        }));
            }


            // get all users
            // if (request.url.endsWith('/api/auth/users') && request.method === 'POST') {
            //     if (!isLoggedIn) { return unauthorised(); }
            // }

            // pass through any requests not handled above
            return next.handle(request);
        }))
            .pipe(materialize())
            .pipe(delay(500))
            .pipe(dematerialize());

        // private helper functions

        function ok(body) {
            return of(new HttpResponse({ status: 200, body }));
        }

        function unauthorised() {
            return throwError({ status: 401, error: { message: 'Unauthorised' } });
        }

        function error(message) {
            return throwError({ status: 400, error: { message:message } });
        }
    }
}

export let AuthProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
};
