import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 3,
    label: 'Dashboard',
    icon: 'home',
    link: '/',
    badge: {
      variant: 'success',
      text: '1',
    },
  },

  // {
  //   id: 4,
  //   label: 'Role Management',
  //   icon: 'user',
  //   link: '#',
  //   subItems: [
  //     {
  //       id: 5,
  //       parentId: 4,
  //       label: 'Create Role',
  //       link: '/apps/create-role',
  //     },
  //     {
  //       id: 6,
  //       parentId: 4,
  //       label: 'Role list',
  //       link: '/apps/role-list',
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   label: 'User Management',
  //   icon: 'users',
  //   subItems: [
  //     {
  //       id: 8,
  //       parentId: 7,
  //       label: 'Create User',
  //       link: '/apps/create-user',
  //     },
  //     {
  //       id: 9,
  //       parentId: 7,
  //       label: 'User list',
  //       link: '/apps/user-list',
  //     },
  //   ],
  // },


  {
    id: 7,
    label: 'Manage User',
    icon: 'users',
    subItems: [
      // {
      //   id: 8,
      //   parentId: 7,
      //   label: 'Create User',
      //   link: '/apps/create-user',
      // },
      {
        id: 9,
        parentId: 7,
        label: 'User list',
        link: '/apps/user-list',
      },
    ],
  },

  // {
    //   id: 10,
  //     label: 'Attendance',
  //     icon: 'calendar',
  //     link: '#',
  //     subItems: [
  //         { id: 11, parentId: 10, label: 'Create attendance', link: '/apps/create-attendance'},
  //         { id: 12, parentId: 10, label: 'Attendance report - Single user', link: '/apps/attendance-report'},
  //         { id: 13, parentId: 10, label: 'Daily Attendance Report', link: '/apps/attendance-daily-report'},
  //         { id: 14, parentId: 10, label : 'Attendance Request List', link: '/apps/attendance-pending-list'},
  //         { id: 15, parentId: 10,
  //             label: 'Leave Management',
  //             subItems: [
  //                 { id: 48, parentId: 15, label: 'Create Leave', link: '/apps/create-leave'},
  //                 { id: 49, parentId: 15, label: 'Leave List', link: '/apps/leave-list'}
  //             ]
  //         }
  //     ]
  // },
  {
    id: 16,
    label: 'Profile Management',
    icon: 'user',
    link: '#',
    subItems: [
      { id: 17, parentId: 16,  label: 'Create Company', link: '/apps/create-company' },
      { id: 18, parentId: 16, label: 'Company List', link: '/apps/company-list' },
      // { id: 19, parentId: 16,label: 'Create Dev. Partner', link: '/apps/create-partner'},
      // { id: 20, parentId: 16,label : 'Development Partner List', link: '/apps/partner-list'},
      // { id: 21, parentId: 16,label : 'Create Employee', link: '/apps/create-employee'},
      // { id: 22, parentId: 16, label: 'Employee List', link: '/apps/employee-list'},
      // { id: 23, parentId: 16, label:'Create Users', link:'/apps/create-users'}
    ],
  },
  //   {  id: 24,
  //     label: 'Company Work Update',
  //     icon: 'user',
  //     link: '#',
  //     subItems: [
  //         { id: 25, parentId: 24, label: 'Add Work Update', link: '/apps/add-work-update'},
  //         { id: 26, parentId: 24, label: 'Daily Work Update List', link: '/apps/work-list'},
  //     ]
  // },

  //      { id: 27,
  //   label: 'Work Update',
  //   icon: 'briefcase',
  //   link: '#',
  //   subItems: [
  //     { id: 28, parentId: 27, label: 'Add Work Update', link: '/apps/add-work-update'},
  //     { id: 29, parentId: 27, label: 'Daily Work Update List', link: '/apps/work-list'},
  //   ]
  // },
  {
    id: 30,
    label: 'Package Management',
    icon: 'briefcase',
    link: '#',
    subItems: [
      { id: 32, parentId: 30, label: 'Package List', link: '/apps/package-list' },
      // { id: 33, parentId: 30, label: 'Package Assign', link: '/apps/package-assign'},
      // { id: 34, parentId: 30, label: 'Package Material', link: ''}, //apps/package-material
      // { id: 35, parentId: 30, label: 'All Package Report', link: ''},///apps/all-package-report
      // { id: 36, parentId: 30, label: 'Create Item', link: '/apps/create-item' },
      // { id: 37, parentId: 30, label: 'Item Board View', link: '/apps/item-board'},
      // { id: 38, parentId: 30, label: 'Item Report Calender View', link: '/apps/item-report-calender'},
      // { id: 39, parentId: 30, label: 'Item Report Timeline View', link: '/apps/item-report-timeline'},
      // { id: 40, parentId: 30, label: 'Create Material Upload', link: '#'},
      // { id: 41, parentId: 30, label : 'Create Procurement', link: '#'},
      // { id: 42, parentId: 30, label: 'Procurement List', link: '#'},
    ],
  },
  // {  id: 43,
  //     label: 'Bill Management',
  //     icon: 'file-text',
  //     link: '#',
  //     subItems:[
  //         { id: 44, parentId: 43, label :'Bill Management', link:'/apps/bill-management'},
  //         { id: 45, parentId: 43, label:'Bill List', link:'/apps/bill-list'},
  //     ]
  // },

  {
    label: 'Bill Management',
    icon: 'briefcase',
    link: '#',
    subItems: [
        {label: 'Bill Management', link: '/apps/create-bill'},
        {label: 'Bill List', link: '/apps/bill-list'},
    ]
  },
  {
    label: 'Report',
    icon: 'file-text',
    link: '#',
    subItems: [
      {label: 'Daily Work Update Report', link: '/apps/daily-work-update-report'},
      {label: 'Package Summary Report', link: '/apps/package-summary-report'},
      {label: 'Company Details Report', link: '/apps/company-details-report'},
      {label: 'Package Wise Item Report', link: '/apps/package-wise-item-report'},
  ]
  },
  // {
  //     label: 'Meeting Management',
  //     icon: 'user',
  //     link: '#',
  //     subItems: [
  //         {label: 'Create Meeting', link: '/apps/create-meeting'},
  //         {label: 'Meeting List', link: '/apps/meeting-list'}
  //     ]
  // },
  // {
  //     label: 'IoT Device and App',
  //     icon: 'package',
  //     link: '#',
  //     subItems: [
  //         {label: 'Add Device', link: '/apps/add-device'},
  //         {label: 'Device List', link: '/apps/device-list'}
  //     ]
  // },
  // {
  //     label: 'Approval Management',
  //     icon: 'file-text',
  //     link: '#',
  //     subItems: [
  //         {label: 'Package Completion Req List', link: '#'},
  //         {label: 'Package Item Approval', link: '#'},
  //         {label: 'Material Approval', link: '#'},
  //         {label : 'Daily or weekly work', link: '#'},
  //         { label: 'report Verification', link: '#'},
  //         { label: 'File Approval', link: '#'},
  //     ]
  // },
  // {
  //   id: 46,
  //   label: 'Live Update',
  //   icon: 'video',
  //   link: '#',
  //   subItems: [
  //     { id: 47, parentId: 46,  label: 'Live Project Monitoring', link: '/apps/live-channel' },
  //   ],
  // },

  // {
  //     label: 'Messaging',
  //     icon: 'inbox',
  //     link: '#',
  //     subItems: [
  //         {label: 'Inbox', link: '#'},
  //         {label: 'Sent Message', link: '#'},
  //         {label: 'Notifications', link: '#'},
  //     ]
  // },
  // {
  //     label: 'Settings',
  //     icon: 'settings',
  //     link: '#',
  //     subItems: [
  //         {label: 'Create Holidays', link: '#'},
  //         {label: 'Working Time Setting', link: '#'}
  //     ]
  // }
];
