import { MenuItem } from './menu.model';

export const MENU_COMPANY: MenuItem[] = [
        {
          id: 3,
          label: 'Dashboard',
          icon: 'home',
          link: '/',
          // badge: {
          //   variant: 'success',
          //   text: '1',
          // },
        },

        {
          id: 7,
          label: 'Manage User',
          icon: 'users',
          subItems: [
            {
              id: 9,
              parentId: 7,
              label: 'User list',
              link: '/apps/user-list',
            },
          ],
        },
        {
          id: 30,
          label: 'Package Management',
          icon: 'briefcase',
          link: '#',
          subItems: [
            // { id: 31, parentId: 30, label: 'Create Package', link: '/apps/create-package' },
            { id: 32, parentId: 30, label: 'Package List', link: '/apps/package-list' },

            // { id: 36, parentId: 30, label: 'Create Item', link: '/apps/create-item' },
          ]
        },
        {
          label: "Bill Management",
          icon: "briefcase",
          link: "#",
          subItems: [
            { label: "Bill Management", link: "/apps/bill-management" },
            { label: "Bill List", link: "/apps/bill-list" },
          ],
        },
      ];
