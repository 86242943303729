import { Injectable } from "@angular/core";
import { User } from "./core/models/auth.models";

const TOKEN_KEY = "AuthToken";
const USER_KEY = "LoggedInUser";
const SELECTED_BILL = "selectedBill";
const SELECTED_PACKAGE = "selectedPackage";
const SELECTED_PACKAGE_LOT = "selectedPackageLot";
const PACKAGE_LOT_ITEM = "packageLotItemTasks";

@Injectable()
export class TokenStorage {
  constructor() {}

  signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);

    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public deleteToken() {
    return sessionStorage.removeItem(TOKEN_KEY);
  }

  public saveUserDetails(user: User) {
    window.sessionStorage.removeItem(USER_KEY);
    if (user != null) {
      window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    } else {
      window.sessionStorage.setItem(USER_KEY, null);
    }
  }

  // public saveUserDetails(user: string) {
  //   window.sessionStorage.removeItem(USER_KEY);
  //   window.sessionStorage.setItem(USER_KEY, user);
  // }
  public getUserDetails(): User {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }

  // public getUserDetails(): string {
  //   return sessionStorage.getItem(USER_KEY);
  // }

  public deleteUserDetails() {
    return sessionStorage.removeItem(USER_KEY);
  }
  public getPackageLotItems() {
    return window.sessionStorage.getItem(PACKAGE_LOT_ITEM);
  }

  public savePackageLotItems(items) {
    window.sessionStorage.removeItem(PACKAGE_LOT_ITEM);
    window.sessionStorage.setItem(PACKAGE_LOT_ITEM, items);
  }
  public deletePackageLotItems() {
    window.sessionStorage.removeItem(PACKAGE_LOT_ITEM);
  }

  public saveSelectedBill(bill: any[]) {

    window.sessionStorage.removeItem(SELECTED_BILL);
    if (bill !== null || bill !== undefined) {
      window.sessionStorage.setItem(SELECTED_BILL, JSON.stringify(bill));
    } else {
      window.sessionStorage.setItem(SELECTED_BILL, null);
    }
  }

  public getSelectedBill(): any[] {
    return JSON.parse(sessionStorage.getItem(SELECTED_BILL));
  }

  public clearSelectedBill() {
    window.sessionStorage.removeItem(SELECTED_BILL);
    window.sessionStorage.removeItem(SELECTED_PACKAGE);
    window.sessionStorage.removeItem(SELECTED_PACKAGE_LOT);
  }

  public getSelectedPackage(): any{
    return JSON.parse(sessionStorage.getItem(SELECTED_PACKAGE));
  }

  public getSelectedPackageLot(): any{
    return JSON.parse(sessionStorage.getItem(SELECTED_PACKAGE_LOT));
  }

  public saveSelectedPackageLot(Package: any) {
    window.sessionStorage.removeItem(SELECTED_PACKAGE_LOT);
    if (Package != null) {
      window.sessionStorage.setItem(SELECTED_PACKAGE_LOT, JSON.stringify(Package));
    } else {
      window.sessionStorage.setItem(SELECTED_PACKAGE_LOT, null);
    }
  }

  public saveSelectedPackage(Package: any) {
    window.sessionStorage.removeItem(SELECTED_PACKAGE);
    if (Package != null) {
      window.sessionStorage.setItem(SELECTED_PACKAGE, JSON.stringify(Package));
    } else {
      window.sessionStorage.setItem(SELECTED_PACKAGE, null);
    }
  }

}
