import { MenuItem } from "./menu.model";

export const SUPER_USER: MenuItem[] = [
  {
    label: "Dashboard",
    icon: "home",
    link: "/",
    // badge: {
    //   variant: 'success',
    //   text: '1',
    // },
  },

  // {
  //   id: 4,
  //   label: 'Role Management',
  //   icon: 'user',
  //   link: '#',
  //   subItems: [
  //     {
  //       id: 5,
  //       parentId: 4,
  //       label: 'Create Role',
  //       link: '/apps/create-role',
  //     },
  //     {
  //       id: 6,
  //       parentId: 4,
  //       label: 'Role list',
  //       link: '/apps/role-list',
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   label: 'User Management',
  //   icon: 'users',
  //   subItems: [
  //     {
  //       id: 8,
  //       parentId: 7,
  //       label: 'Create User',
  //       link: '/apps/create-user',
  //     },
  //     {
  //       id: 9,
  //       parentId: 7,
  //       label: 'User list',
  //       link: '/apps/user-list',
  //     },
  //   ],
  // },

  //   {  id: 24,
  //     label: 'Company Work Update',
  //     icon: 'user',
  //     link: '#',
  //     subItems: [
  //         { id: 25, parentId: 24, label: 'Add Work Update', link: '/apps/add-work-update'},
  //         { id: 26, parentId: 24, label: 'Daily Work Update List', link: '/apps/work-list'},
  //     ]
  // },

  //      { id: 27,
  //   label: 'Work Update',
  //   icon: 'briefcase',
  //   link: '#',
  //   subItems: [
  //     { id: 28, parentId: 27, label: 'Add Work Update', link: '/apps/add-work-update'},
  //     { id: 29, parentId: 27, label: 'Daily Work Update List', link: '/apps/work-list'},
  //   ]
  // },
  {
    label: "Package",
    isTitle: true,
  },
  {
    label: "Package Management",
    icon: "briefcase",
    link: "#",
    subItems: [
      { label: "Create Package", link: "/apps/create-package" },
      { label: "Package List", link: "/apps/package-list" },
      { label: "Package Assign", link: "/apps/package-assign" },
      // { id: 35, parentId: 30, label: 'All Package Report', link: ''},///apps/all-package-report
      // { id: 36, parentId: 30, label: 'Create Item', link: '/apps/create-item' },
      // { id: 37, parentId: 30, label: 'Item Board View', link: '/apps/item-board'},
      // { id: 38, parentId: 30, label: 'Item Report Calender View', link: '/apps/item-report-calender'},
      // { id: 39, parentId: 30, label: 'Item Report Timeline View', link: '/apps/item-report-timeline'},
      // { id: 34, parentId: 30, label: 'Add Package Material ', link: '/apps/create-material'}, //apps/package-material
      // { id: 34, parentId: 30, label: 'Add Package Receive Material ', link: '/apps/package-material-upload'}, //apps/package-material
      // { id: 40, parentId: 30, label: 'Create Material', link: '/apps/create-material'},
      // { id: 41, parentId: 30, label : 'Create Procurement', link: '#'},
      // { id: 42, parentId: 30, label: 'Procurement List', link: '#'},
    ],
  },
  // {  id: 43,
  //     label: 'Bill Management',
  //     icon: 'file-text',
  //     link: '#',
  //     subItems:[
  //         { id: 44, parentId: 43, label :'Bill Management', link:'/apps/bill-management'},
  //         { id: 45, parentId: 43, label:'Bill List', link:'/apps/bill-list'},
  //     ]
  // },

  {
    label: "Bill Management",
    icon: "briefcase",
    link: "#",
    subItems: [
      { label: "Bill Management", link: "/apps/bill-management" },
      { label: "Bill List", link: "/apps/bill-list" },
    ],
  },
  {
    label: "Approval Management",
    icon: "briefcase",
    link: "#",
    subItems: [
      // { id: 47, parentId: 46,  label: 'Live Project Monitoring', link: '/apps/live-channel' },
      {
        label: "Package Completion List",
        link: "/apps/package-completion-list",
      },
      { label: "Material Approval List", link: "/apps/material-approval-list" },
    ],
  },
  {
    label: "Meeting Management",
    icon: "user",
    link: "#",
    subItems: [
      { label: "Create Meeting", link: "/apps/create-meeting" },
      { label: "Meeting List", link: "/apps/meeting-list" },
    ],
  },
  {
    label: "IoT Device and App",
    icon: "package",
    link: "#",
    subItems: [
      // {label: 'Add Device', link: '/apps/add-device'},
      { label: "IoT Device Reg", link: "/apps/device-list/0" },
      // { label: " Device Discovering", link: "/apps/device-list/1" },
      { label: "Live Project Monitoring", link: "/apps/live-channel" },
    ],
  },
  // {
  //     label: 'Approval Management',
  //     icon: 'file-text',
  //     link: '#',
  //     subItems: [
  //         {label: 'Package Completion Req List', link: '#'},
  //         {label: 'Package Item Approval', link: '#'},
  //         {label: 'Material Approval', link: '#'},
  //         {label : 'Daily or weekly work', link: '#'},
  //         { label: 'report Verification', link: '#'},
  //         { label: 'File Approval', link: '#'},
  //     ]
  // },
  // {
  //   label: "Live Update",
  //   icon: "video",
  //   link: "#",
  //   subItems: [
  //     { label: "Live Project Monitoring", link: "/apps/live-channel" },
  //   ],
  // },
  {
    label: 'Report',
    icon: 'file-text',
    link: '#',
    subItems: [
        {label: 'Daily Work Update Report', link: '/apps/daily-work-update-report'},
        {label: 'Package Summary Report', link: '/apps/package-summary-report'},
        {label: 'Company Details Report', link: '/apps/company-details-report'},
        {label: 'Package Wise Item Report', link: '/apps/package-wise-item-report'},
    ]
  },
  {
    label: "Others",
    isTitle: true,
  },
  {
    label: "HRM",
    icon: "users",
    subItems: [
      {
        id: 10,
        label: "Attendance",
        icon: "calendar",
        link: "#",
        subItems: [
          // {
          //   id: 11,
          //   parentId: 10,
          //   label: "Create attendance",
          //   link: "/apps/create-attendance",
          // },
          {
            id: 11,
            parentId: 10,
            label: "Create Bulk attendance",
            link: "/apps/create-bulk-attendance",
          },
          // {
          //   id: 12,
          //   parentId: 10,
          //   label: "Attendance report - Single user",
          //   link: "/apps/attendance-report",
          // },
          {
            id: 13,
            parentId: 10,
            label: "Daily Attendance Report",
            link: "/apps/attendance-daily-report",
          },
          // {
          //   id: 14,
          //   parentId: 10,
          //   label: "Attendance Request List",
          //   link: "/apps/attendance-pending-list",
          // },
          // {
          //   id: 15,
          //   parentId: 10,
          //   label: "Leave Management",
          //   subItems: [
          //     {
          //       id: 48,
          //       parentId: 15,
          //       label: "Create Leave",
          //       link: "/apps/create-leave",
          //     },
          //     {
          //       id: 49,
          //       parentId: 15,
          //       label: "Leave List",
          //       link: "/apps/leave-list",
          //     },
          //   ],
          // },
        ],
      },
      {
        id: 15,
        parentId: 10,
        icon: "calendar",
        label: "Leave Management",
        subItems: [
          {
            id: 48,
            parentId: 15,
            label: "Create Leave",
            link: "/apps/create-leave-request",
          },
          {
            id: 49,
            parentId: 15,
            label: "Leave Approval List",
            link: "/apps/leave-approval-list",
          },
        ],
      },
      {
        label: "Leave Policy",
        icon: "calendar",
        subItems: [
          { label: "Create Leave Policy", link: "/apps/create-leave-policy" },
          { label: "Leave Policy List", link: "/apps/leave-policy-list" },
        ],
      },
    ],
  },
  {
    label: "Manage User",
    icon: "users",
    subItems: [
      // {
      //   id: 8,
      //   parentId: 7,
      //   label: 'Create User',
      //   link: '/apps/create-user',
      // },
      {
        label: "User list",
        link: "/apps/user-list",
      },
    ],
  },

  {
    label: "Profile Management",
    icon: "user",
    link: "#",
    subItems: [
      { label: "Create Company", link: "/apps/create-company" },
      { label: "Company List", link: "/apps/company-list" },
      // { id: 19, parentId: 16,label: 'Create Dev. Partner', link: '/apps/create-partner'},
      // { id: 20, parentId: 16,label : 'Development Partner List', link: '/apps/partner-list'},
      // { id: 21, parentId: 16,label : 'Create Employee', link: '/apps/create-employee'},
      // { id: 22, parentId: 16, label: 'Employee List', link: '/apps/employee-list'},
      // { id: 23, parentId: 16, label:'Create Users', link:'/apps/create-users'}
    ],
  },
  {
    label: "Settings",
    icon: "settings",
    link: "#",
    subItems: [
      // { id: 47, parentId: 46,  label: 'Live Project Monitoring', link: '/apps/live-channel' },
      {
        label: "Group",
        subItems: [
          { label: "Create Group", link: "/apps/create-group" },
          { label: "Group List", link: "/apps/group-list" },
        ],
      },
      {
        label: "Unit",
        subItems: [
          { label: "Create Unit", link: "/apps/create-unit" },
          { label: "Unit List", link: "/apps/unit-list" },
        ],
      },
    ],
  },

  // {
  //     label: 'Messaging',
  //     icon: 'inbox',
  //     link: '#',
  //     subItems: [
  //         {label: 'Inbox', link: '#'},
  //         {label: 'Sent Message', link: '#'},
  //         {label: 'Notifications', link: '#'},
  //     ]
  // },
  // {
  //     label: 'Settings',
  //     icon: 'settings',
  //     link: '#',
  //     subItems: [
  //         {label: 'Create Holidays', link: '#'},
  //         {label: 'Working Time Setting', link: '#'}
  //     ]
  // }
];
