export class User {
  id: number;
  username?: string;
  password?: string;
  firstName: string;
  lastName: string;
  user_type: string;
  access_token?: string;
  email: string;
  roles: any[];
  companies: any[];
}

export class LoginUserRO {
  success: boolean;
  access_token?: string;
  data?: string;
}

export class LoggedInUserRO {
  success: boolean;
  data?: any;
  error?: string;
  message: number;
}
