import { SuperAdmin1, Type_A_Com_Admin, Type_A_Com_User, Type_B_Com_User, Type_B_Com_Admin, Type_C_Com_User, Type_C_Com_Admin } from 'src/app/app.component';


export class CheckUserType {


   PACKLAGE_STATUS_Request_To_Complete : string = 'request-to-complete';
   PACKLAGE_STATUS_Completed : string = 'completed';

  isPermission1(type: string) {

    if (SuperAdmin1 === type || Type_A_Com_Admin === type) {
      return true;
    } else {
      return false;
    }
  }

  isPermission2(type: string) {

    if (SuperAdmin1 === type || Type_A_Com_Admin === type || Type_A_Com_User === type) {
      return true;
    } else {
      return false;
    }
  }

  isPermission3(type: string) {

    if (SuperAdmin1 === type || Type_A_Com_Admin === type || Type_A_Com_User === type || Type_B_Com_Admin === type ) {
      return true;
    } else {
      return false;
    }
  }
  

  isPermission4(type: string) {

    if (SuperAdmin1 === type || Type_A_Com_Admin === type || Type_A_Com_User === type || Type_B_Com_Admin === type || Type_B_Com_User === type || Type_C_Com_Admin === type) {
      return true;
    } else {
      return false;
    }
  }

  isPermission5(type: string) {

    if (SuperAdmin1 === type || Type_A_Com_Admin === type || Type_A_Com_User === type || Type_B_Com_Admin === type || Type_B_Com_User === type) {
      return true;
    } else {
      return false;
    }
  }


  isAdmin0(type: string) {

    if (SuperAdmin1 === type) {
      return true;
    } else {
      return false;
    }
  }

  isAdmin1(type: string) {

    if (Type_A_Com_Admin === type || Type_A_Com_User === type ||  SuperAdmin1 === type) {
      return true;
    } else {
      return false;
    }
  }

  isAdmin2(type: string) {

    if (Type_B_Com_Admin === type || Type_B_Com_User === type) {
      return true;
    } else {
      return false;
    }
  }


  isTopAdminUserAndAdminAccess12(type: string) {

    if (Type_A_Com_Admin === type || Type_B_Com_Admin === type || SuperAdmin1 === type
      || Type_A_Com_User === type || Type_B_Com_User === type ) {
      return true;
    } else {
      return false;
    }
  }


  
  isTopAdminAccess12(type: string) {

    if (Type_A_Com_Admin === type || Type_B_Com_Admin === type || SuperAdmin1 === type) {
      return true;
    } else {
      return false;
    }
  }


  isSuperAdmin(type: string) {

    if (Type_A_Com_Admin === type || Type_B_Com_Admin === type || Type_C_Com_Admin === type || SuperAdmin1 === type) {
      return true;
    } else {
      return false;
    }
  }

  addUser(type: string) {

    if (Type_A_Com_Admin === type || Type_A_Com_User === type || SuperAdmin1 === type) {
      return true;
    } else {
      return false;
    }
  }




  checkUser(type : string) : number {

    if (SuperAdmin1 === type) {
       return 1;
    } else  if (Type_A_Com_Admin === type || Type_A_Com_User === type) {
       return 2;
    } else  if (Type_B_Com_Admin === type || Type_B_Com_User === type) {
       return 3;
    } else  if (Type_C_Com_User === type || Type_C_Com_Admin === type) {
       return 4;
    }

  }

  getCompanyUser(type : string) : number {
   if (Type_A_Com_Admin === type || Type_A_Com_User === type || SuperAdmin1 === type) {
       return 1;
    } else  if (Type_B_Com_Admin === type || Type_B_Com_User === type) {
       return 2;
    } else  if (Type_C_Com_User === type || Type_C_Com_Admin === type) {
       return 3;
    }

  }


  isCompanyAdmin(type: string) {

    if (Type_C_Com_User === type || Type_C_Com_Admin === type) {
      return true;
    } else {
      return false;
    }
  }

}
