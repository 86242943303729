import { TokenStorage } from '../../token.storage';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CookieService } from '../services/cookie.service';
import { User, LoginUserRO, LoggedInUserRO } from '../models/auth.models';
import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  user: any;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private tokenStorage: TokenStorage
  ) {}

  /**
   * Returns the current user
   */
  public currentUser(): User {
    if (!this.user) {
      // this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
      this.user = JSON.parse(this.tokenStorage.getToken());
    }
    return this.user;
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    const API_URL = `${environment.api_url}/api/auth/login`;

    return this.http
      .post<LoginUserRO>(`/api/auth/login`, { email, password })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.access_token) {
            this.user = user;
            // store user details and jwt in cookie
            // this.cookieService.setCookie('currentUser', JSON.stringify(user), 1);
            this.tokenStorage.saveToken(JSON.stringify(user));
          }
          return user;
        })
      );
  }

  getUser() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const API_URL = `${environment.api_url}/api/auth/user`;
    this.http
      .post<LoggedInUserRO>(`/api/auth/user`, {}, { headers: headers })
      .subscribe((data) => {
        console.log('User details response ' + JSON.stringify(data));
        if (!data.success) {
          console.log('User Deatails Error :' + data.error);
          return;
        }
        this.tokenStorage.saveUserDetails(data.data);
      });
  }

  /**
   * Logout the user
   */
  logout() {
    const currentUser = this.currentUser();

    if (currentUser && currentUser.access_token) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const API_URL = `${environment.api_url}/api/auth/logout`;

      this.tokenStorage.signOut();
      this.user = null;
      this.router.navigate(['/account/login'], {
        queryParams: { returnUrl: '/' },
      });
      //    this.http.post<any>(`/api/auth/logout`,{},{headers})
      //         .subscribe(data =>{
      //               console.log("Logout response "+JSON.stringify(data));
      //                 if(data.success){
      //                  //this.cookieService.deleteCookie('currentUser');

      //              }else{
      //                    console.log("Logout error "+data.error);
      //                 }
      //         })
    }
  }
}
