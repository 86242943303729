import { User } from './../../../core/models/auth.models';
import { TokenStorage } from './../../../token.storage';
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { SIDEBAR_WIDTH_CONDENSED } from '../../layout.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoggedInUserRO } from 'src/app/core/models/auth.models';
import { CheckUserType } from 'src/app/pages/model/checkUserType';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.scss'],
})
export class LeftsidebarComponent implements OnInit {
  @Input() sidebarType: string;

  user: User;
  userType: any = 'User';
  timeIntevalSeconds: number = 90;
  isVisible=false;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private token: TokenStorage,
    private changeDetectorRefs: ChangeDetectorRef,
    private http: HttpClient
  ) {}

  ngOnInit() {

    this.user = new User();
    // this.checkUserType = new CheckUserType();

    let refreshIntervalId = setInterval(() => {

      if(this.token.getUserDetails() != null || this.token.getUserDetails() != undefined)
      {
        this.user = this.token.getUserDetails();
          this.initialize();
         clearInterval(refreshIntervalId);
       }
     },this.timeIntevalSeconds * 10);



  }

  initialize(){
    if (this.user.user_type == 'super_admin') {
      this.userType = 'Super Admin';
    } else if (this.user.user_type === 'ct_a_authority_admin') {
      this.userType = 'Project Authority Admin';
    } else if (this.user.user_type === 'ct_a_authority_user') {
      this.userType = 'Project Authority User';
    } else if (this.user.user_type === 'ct_b_adviser_admin') {
      this.userType = 'Technical Advisor Admin';
    } else if (this.user.user_type === 'ct_b_adviser_user') {
      this.userType = 'Technical Advisor User';
    } else if (this.user.user_type === 'ct_c_partner_admin') {
      this.userType = 'Development Partner Admin';
    } else if (this.user.user_type === 'ct_c_partner_user') {
      this.userType = 'Development Partner User';
    }
    // this.changeDetectorRefs.detectChanges();
    console.log('current user ' + JSON.stringify(this.user));
  }
  /**
   * Is sidebar condensed?
   */
  isSidebarCondensed() {
    return this.sidebarType === SIDEBAR_WIDTH_CONDENSED;
  }

  /**
   * Logout the user
   */
  logout() {
    this.authenticationService.logout();
  }
}
